<template>
    <b-card header="Select Incident" header-bg-variant="secondary" header-text-variant="white" no-body>
        <b-list-group flush>
            <b-list-group-item :to='`/openapp/${locationId}/default-messaging/${item.id}`'
                v-for="item in incidentList" :key="item.id"
                class="d-flex w-100 justify-content-between">
                <div>
                    <h5 class="mb-1">{{ item.incidentDateTime | formatLongDateTime }}</h5>
                    <p class="mb-1">
                        {{ item.whatHappenned }}
                    </p>
                </div>
                <div class="align-items-center">
                    <b-badge variant="success">{{ item.status }}</b-badge>
                </div>
            </b-list-group-item>
            <b-list-group-item href="#" class="w-100" variant="primary">
                <div>
                    <h5 class="mb-4">New Incident - Please select one</h5>
                </div>
                <b-list-group>
                    <b-list-group-item @click="createIncident('I need medical help')">I need medical
                        help</b-list-group-item>
                    <b-list-group-item @click="createIncident('I am lost and need help')">I am lost
                        and need help</b-list-group-item>
                    <b-list-group-item @click="createIncident('There is an emergency')">There is an
                        emergency</b-list-group-item>
                    <b-list-group-item href="tel:000">Call 000</b-list-group-item>
                </b-list-group>
            </b-list-group-item>
        </b-list-group>
    </b-card>
</template>

<script>

import {
    incidentData
} from '@/src/state/api/incident';

export default {
    data() {
        return {
            currentDateTime: new Date(),
            incidentList: []
        }
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
    },
    async created() {
        await this.loadIncidents()
    },
    methods: {
        async loadIncidents() {
            var result = await incidentData.searchMyIncidents({
                actionDateTime: this.currentDateTime
            })
            if (result.succeeded) {
                this.incidentList = result.data;
                return;
            }
        },
        async createIncident(message) {

            var payload = {
                locationId: this.locationId,
                incidentMessage: message,
                requestDateTime: this.currentDateTime
            }

            var result = await incidentData.createIncident(payload);
            if (!result.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: result.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            await this.loadIncidents();
            this.$router.push(`/openapp/${this.locationId}/default-messaging/${result.data}`)
        },
    }
}

</script>